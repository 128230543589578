<template>
  <div class="box">
    <div class="box_title">
      <h1>退货运单</h1>
    </div>
    <div class="box_forms">
      <a-form-model :model="form" layout='inline'>
        <a-form-model-item label="运单号">
          <a-input v-model="form.waybillNo" />
        </a-form-model-item>
        <!-- <a-form-model-item label="申请日期">
          <a-range-picker v-model="date" :show-time="{
        hideDisabledOptions: true,
        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
      }" format="YYYY-MM-DD HH:mm:ss" @change='change' />
        </a-form-model-item> -->

        <a-form-model-item>
          <div class="btns">
            <a-button type='primary' @click="search">查询</a-button>
            <a-button style="margin-left:15px" @click="reset">重置</a-button>
          </div>
        </a-form-model-item>

      </a-form-model>

    </div>
    <div class="box_table">
      <a-table size="small" :columns="columns" :data-source="data" :scroll="{ x: 'max-content' }" :rowKey="(record) => record.id" bordered>
        <!-- <template slot="created_at" slot-scope="text">
          <span>{{moment(text *1000).format("YYYY-MM-DD HH:mm:ss")}}</span>
        </template> -->
        <a slot="name" slot-scope="text,record">
          <a-button type="link" @click="editWaybill(record)">修改运单</a-button>
        </a>
      </a-table>
    </div>
    <a-modal v-model="modal.show" :title="modal.title" centered @ok="editSubmit">
      <a-form-model ref="defForm" :model="waybillForm" :label-col="labelCol" :wrapper-col="wrapperCol"
        v-if="modal.show">
        <a-form-model-item label="件数 " prop="boxCount" :rules="validate({name: 'value', msg: '请输入件数'})">
          <a-input-number v-model="waybillForm.boxCount"></a-input-number>
        </a-form-model-item>

        <a-form-model-item label="国家" prop="countryId" :rules="validate({name: 'value', msg: '请选择国家'})">
          <a-select v-model="waybillForm.countryId" placeholder="请选择国家" @change="countryChange">
            <a-select-option v-for="(item,index) in countryData" :key="index" :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="渠道" prop="channelId" :rules="validate({name: 'value', msg: '请选择渠道'})">
          <a-select v-model="waybillForm.channelId" placeholder="请选择渠道" show-search :filter-option="filterOption">
            <a-select-option v-for="(item,index) in channelData" :key="index" :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input v-model="waybillForm.remark" placeholder="请输入备注"></a-input>
        </a-form-model-item>
        <a-form-model-item label="品名" prop="remark">
          <a-input v-model="waybillForm.newProductName" placeholder="请输入备注"></a-input>
        </a-form-model-item>
        <a-form-model-item label="材质" prop="newMaterialCates" :rules="validate({ name: 'value', msg: '请选择材质' })">
          <a-checkbox-group v-model="waybillForm.newMaterialCates" :options="plainOptions">
            <span slot="label" slot-scope="{ value }" style="color: red">{{ value }}</span>
          </a-checkbox-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>

</template>

<script>
  import moment from 'moment'
  import {
    waybillBack,
    waybillInfo,
    waybillEdit
  } from "@/api/ReturnWaybill"
  import {
    countryList,
    channelCountry,
    materialList
  } from "@/api/comm"
  export default {
    name: 'ReturnOrder',
    data() {
      return {
        date: [],
        countryData: [],
        plainOptions: [],
        waybillForm: {
          channelId: '',
          countryId: '',
          remark: '',
          boxCount: '',
          newProductName: '',
          newMaterialCates: []
        },
        channelData: [],
        labelCol: {
          span: 4
        },
        wrapperCol: {
          span: 16
        },
        modal: {
          show: false,
          title: "修改运单"
        },
        form: {
          applyDateStart: '',
          applyDateEnd: '',
          waybillNo: ''
        },
        moment,
        infoData: {},
        data: [{
          "id": 756,
          "waybill_no": "ASSZ000019578",
          "type": 0,
          "created_at": 1660795076,
          "channel_name": "广州FEDEX-IP",
          "has_invoice": 0,
          "original": "深圳市",
          "destination": "美国",
          "customer_name": "李严",
          "customer_code": "李严",
          "cargoes_num": 7,
          "remark": ""
        }],
        columns: [{
            title: '运单号',
            dataIndex: 'waybill_no',
            key: 'waybill_no',
            width: 150,
          },
          {
            title: '预报类型',
            dataIndex: 'type',
            width: 120,
            key: 'type',
            customRender: (text) => {
              let str = ""
              if (text === 1) {
                str = "计划"
              } else if (text === 2) {
                str = "未建计划"
              } else {
                str = "/"

              }
              return str;
            }
          },
          {
            title: '件数',
            dataIndex: 'cargoes_num',
            width: 160,
            key: 'cargoes_num',
          },
          {
            title: '目的国',
            dataIndex: 'destination',
            width: 100,
            key: 'destination',

          },
          {
            title: '寄件类型',
            dataIndex: 'receive_type',
            width: 160,
            key: 'receive_type',
            customRender: (text) => {
              let str = ""
              if (text === 1) {
                str = "上门取件"
              } else if (text === 2) {
                str = "自送入仓"
              } else {
                str = "/"
              }
              return str;
            }

          },
          {
            title: '下单时间',
            dataIndex: 'created_at',
            width: 180,
            key: 'created_at',
            customRender: (text) => {
              let str = ""
              if (text) {
                str = moment(text * 1000).format("YYYY-MM-DD HH:mm:ss")
              } else if (text === 2) {
                str = "-"
              }
              return str;
            }
          },
          {
            title: '渠道',
            dataIndex: 'channel_name',
            width: 200,
            key: 'channel_name',
          },
          {
            title: '操作',
            dataIndex: 'name',
            key: 'name',
            scopedSlots: {
              customRender: 'name'
            },
            width: 250
          },

        ]
      }
    },
    created() {
      this.getData()
      materialList().then(res => {
        for (let i of res.data) {
          if (['普货', '带电', '带磁', '其他'].indexOf(i.name) != -1) {
            this.plainOptions.push({
              label: i.name,
              value: i.id
            })
          }
        }
      })

    },
    methods: {
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      editWaybill(row) {
        this.modal.show = true
        this.waybillForm.newProductName = row.new_product_name
        if (row.new_material_cate_items) {
          this.waybillForm.newMaterialCates = row.new_material_cate_items.map(ele => {
            return ele.id
          })
        }
        waybillInfo({
          waybillId: row.id
        }).then(res => {
          this.infoData = res.data
          this.waybillForm.channelId = this.infoData.channel_id
          this.waybillForm.boxCount = this.infoData.cargoes_num
          this.waybillForm.remark = this.infoData.remark
          countryList().then(res => {
            this.countryData = res.data.list
            for (let i of this.countryData) {
              if (i.name == this.infoData.destination) this.waybillForm.countryId = i.id
            }
            channelCountry({
              countryId: this.waybillForm.countryId
            }).then(res => {
              this.channelData = res.data
            })
          })
        })
      },
      countryChange(val) {
        this.waybillForm.channelId = ''
        channelCountry({
          countryId: val
        }).then(res => {
          this.channelData = res.data
        })
      },
      getData() {
        waybillBack().then(res => {
          this.data = res.data.list
        })
      },
      editSubmit() {
        this.$refs.defForm.validate(val => {
          if (val) {
            waybillEdit({
              waybillId: this.infoData.id,
              ...this.waybillForm
            }).then(() => {
              this.$message.success("修改成功")
              this.modal.show = false
              this.getData()
            })
          } else {
            this.$message.error("请完整数据")
          }
        })

      },
      change(val) {
        this.form.applyDateStart = moment(val[0]).format('YYYY:MM:DD HH:mm:ss')
        this.form.applyDateEnd = moment(val[1]).format('YYYY:MM:DD HH:mm:ss')
      },
      reset() {
        this.date = []
        Object.keys(this.form).forEach(ele => {
          this.form[ele] = ''
        })
      },
      search() {
        waybillBack(this.form).then(res => {
          this.data = res.data.list
        })
      },
    },

  }
</script>

<style lang="less" scoped>
  .box {
    position: relative;
    .box_title {
      padding-top: 10px;
      padding-left: 15px;
      border-bottom: 1px solid #e9e8eb;
    }

    .box_forms {
      padding: 15px;
      border-bottom: 1px solid #e9e8eb;

    }

    .box_table {
      padding: 15px;

    }
  }
</style>